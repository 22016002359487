<template>
  <div class='content'>
    <div v-if="!addVisiable && !detailVisiable && !examineVisiable">
      <div class='card' style='display: flex;'>
        <div class="left">
          <!-- <el-button type='primary' @click='dialogVisible = true' icon='el-icon-printer'>打印</el-button> -->
          <el-button type='primary' plain @click='handleExport' icon='el-icon-upload2' :loading="loading3">导出</el-button>
        </div>
        <div class='line'></div>
        <div class="middle">
          <Cond @onCond="handleCond" function_code="warranty" />
        </div>
        <div class="right">
          <TableConf function_code="warranty" @onRowList="handleRowList"/>
        </div>
      </div>
      <div class='table' v-if="rowList.length">
        <el-table :data='data.list' stripe v-loading="loading" height="700" @row-dblclick="handleDalclick">
          <template v-for='(item, index) in rowList'>
            <el-table-column
              v-if="item.filed_status"
              align='center'
              :key='index'
              :prop="item.field_alias"
              :label='item.field_text'
              :width="item.t_width"
              :filters="item.filed_status"
              :filter-method="filterHandler"
            >
              <template slot-scope="scope">
                <template v-if="item.field_alias !== 'goods_pic'">
                  {{scope.row[item.field_alias] ? scope.row[item.field_alias]: '--'}}
                </template>
                <template v-else>
                  <el-image :src="scope.row[item.field_alias]" :preview-src-list="[scope.row[item.field_alias]]">
                      <div slot="error" class="image-slot">
                        <img src="../../../../assets/images/no_img.png" />
                      </div>
                  </el-image>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              align='center'
              :key='index'
              :prop="item.field_alias"
              :label='item.field_text'
              :width="item.t_width"
            >
              <template slot-scope="scope">
                <template v-if="item.field_alias !== 'goods_pic'">
                  {{scope.row[item.field_alias] ? scope.row[item.field_alias]: '--'}}
                </template>
                <template v-else>
                  <el-image :src="scope.row[item.field_alias]" fit="cover" :preview-src-list="[scope.row[item.field_alias]]" style="width: 46px; height: 46px; ">
                      <div slot="error" class="image-slot">
                        <img src="../../../../assets/images/no_img.png" style="width: 46px; height: 46px; margin-left: -7px;" />
                      </div>
                  </el-image>
                </template>
              </template>
            </el-table-column>
          </template>
          <el-table-column align='center' fixed='right' field_alias='operation' label='操作'>
            <template slot-scope="scope">
              <el-button type="text" @click="toDetail(scope.row.pdf_path)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class='pagina'>
          <el-pagination
            background
            :current-page="page"
            @size-change="handleSizeChange"
            layout='total, sizes, prev, pager, next, jumper'
            :total='data.total'
            :page-size='$store.state.pageSizes[0]'
            :page-sizes="$store.state.pageSizes"
            @current-change='handleCurrentChange'
          ></el-pagination>
        </div>
      <el-dialog
        title="请先选择品类"
        :visible.sync="dialogVisible"
        width="30%"
        :destroy-on-close="true"
        :before-close="handleClose">
        <el-form :model="form" :rules="rules" ref="ruleForm">
          <el-form-item label="品类" label-width="100px" required prop="goods_type_id">
            <el-select v-model="form.goods_type_id">
              <template v-for="item in select">
                  <el-option :label="item.goods_type_name" :value="item.goods_type_id" :key="item.goods_type_id">
                    <span :style="{marginLeft: item.span * 8 +'px'}">{{item.goods_type_name}}</span>
                  </el-option>
                </template>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <el-dialog
      title="失败原因"
      :visible.sync="reasonVisible"
      width="30%"
      :before-close="handleClose">
      <span>
        {{currRow.audit_remark || '--'}}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listReq } from '@/api/order/warranty/list';
import moment from 'moment';
import Cond from '@/components/cond/Index.vue';
import TableConf from '@/components/tableConf/Index.vue';

export default {
  data() {
    return {
      loading3: false,
      reasonVisible: false,
      currRow: {},
      select: [],
      propObj: {},
      addVisiable: false,
      detailVisiable: false,
      examineVisiable: false,
      rowList: [],
      loading2: false,
      rules: {
        goods_type_id: [
          { required: true, message: '请选择品类' },
        ],
      },
      merchantList: [],
      loading: false,
      form: {},
      dialogVisible: false,
      total: 0,
      tableVisiable: false,
      cond: {},
      page: 1,
      limit: this.$store.state.pageSizes[0],
      // coordinate: { column: -1, row: -1 },
      data: [],
    };
  },
  components: {
    Cond,
    TableConf,
  },
  created() {
    this.getList();
    // this.getTypeList();
  },
  methods: {
    handleDalclick(row) {
      const { pdf_path } = row;
      if (pdf_path) {
        window.open(pdf_path, '_black');
      } else {
        this.$message.error('无法生成pdf');
      }
    },
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.getList();
    },
    toDetail(pdf_path) {
      if (pdf_path) {
        window.open(pdf_path, '_black');
      } else {
        this.$message.error('无法生成pdf');
      }
    },
    handleHideDetail() {
      this.detailVisiable = false;
      this.currRow = {};
    },
    handleHideAdd() {
      this.form = {};
      this.currRow = {};
      this.addVisiable = false;
    },
    handleArr(arr) {
      const newArr = [];
      function poling(arr, span) {
        arr.forEach((item) => {
          if (span || span === 0) {
            item.span = span + 1;
            item.goods_type_name = '├─' + item.goods_type_name;
          } else {
            item.span = 0;
          }
          newArr.push(item);
          if (item.children) {
            poling(item.children, item.span);
          }
        });
      }
      // 深复制
      const aArr = JSON.parse(JSON.stringify(arr));
      poling(aArr);
      return newArr;
    },
    // getTypeList() {
    //   getTypeListReq()
    //     .then((res) => {
    //       if (res.code === 1) {
    //         this.treeData = res.data;
    //         // 如果有数据，当前不是新增并且获取对应详情
    //         if (res.data.length) {
    //           this.currentKey = res.data[0].goods_type_id;
    //           this.currItem = res.data[0];
    //           this.select = this.handleArr(res.data);
    //         }
    //       }
    //     });
    // },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 成功的操作
          this.addVisiable = true;
          this.dialogVisible = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    cancelTable() {
      const coordinate = { ...this.coordinate };
      coordinate.row = -1;
      coordinate.column = -1;
      this.coordinate = coordinate;
      this.tableVisiable = false;
      this.tableVisiable2 = false;
      this.currRow.select = '';
    },
    filterHandler(value, row, column) {
      const { property } = column;
      return row[property] === value;
    },
    // 处理表格数据
    handleRowList(rowList) {
      this.rowList = rowList;
    },
    handleCond(cond) {
      this.page = 1;
      this.cond = { ...cond };
      this.getList();
    },
    getList() {
      this.loading = true;
      listReq({ ...this.cond, page: this.page, limit: this.limit })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            // res.data.list.forEach((item) => {
            //   item.create_time && (item.create_time = moment(item.create_time * 1000).format('yyyy-MM-DD hh:mm:ss'));
            //   item.audit_time && (item.audit_time = moment(item.audit_time * 1000).format('yyyy-MM-DD hh:mm:ss'));
            // });
            this.data = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleClose () {
      this.dialogVisible = false;
      this.reasonVisible = false;
      this.form = {};
      this.cancelTable();
    },
    fetchData() {
      this.getList();
    },
    /*
     *  导出
     */
    handleExport() {
      this.loading3 = true;
      listReq({ ...this.cond, page: this.page, page_num: this.limit, function_code: 'warranty', export: 2 })
        .then((res) => {
          this.loading3 = false;
          let blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute("download", `${moment().format('yyyy-MM-DD')}质保单列表.csv`);
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch(() => {
          this.loading3 = true;
        });
    },
    /*
     *  新增入库
     */
    handleAdd() {
      this.addVisiable = true;
    },
    /*
    /*
     *  分页操作
     */
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    /*
     *  关闭列表配置弹窗
     */
    // cancelTable() {
    //   const coordinate = { ...this.coordinate };
    //   coordinate.row = -1;
    //   coordinate.column = -1;
    //   this.coordinate = coordinate;
    //   this.tableVisiable = false;
    // },
    /*
     *  关闭选择入库类型弹窗
     */
    cancelAdd() {
      this.addVisiable = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-image {
  margin-top: 3px;
  margin-bottom: 3px;
}
/deep/ .image-slot {
  width: 54px;
  height: 54px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.middle {
  flex: 1;
}
</style>
